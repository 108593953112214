<template>
  <div class="shaka-player" webkit-playsinline="false"></div>
</template>

<script>
import shaka from "shaka-player";

export default {
  name: "ShakaPlayer",
  components: {},
  props: ["video"],
  data() {
    return {
      player: null
    };
  },
  watch: {},
  computed: {},
  created() {},
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.log("[ShakaPlayer] - init");
      this.log("[ShakaPlayer] - version:", shaka.Player.version);
      this.log(
        "[ShakaPlayer] - isBrowserSupported:",
        shaka.Player.isBrowserSupported()
      );
      shaka.polyfill.installAll();

      this.configure();
    },
    configure() {
      // Create player and bind event listeners.
      this.player = new shaka.Player(this.video);
      // this.setConfiguration();
      this.setPlayerEvents();
    },
    setPlayerEvents() {
      this.player.addEventListener("adaptation", this.onAdaptationEvent);
      this.video.addEventListener("timeupdate", this.onTimeUpdate);
      this.video.addEventListener("timeupdate", this.onBufferedData);
      this.player.addEventListener("loaded", this.loaded);
    },
    async load(url, drm, startTime = 0) {
      // Set DRM (if provided).
      if (drm) {
        await this.setProtection();
      }

      this.player.addBigPlayButton;
      // Load url.
      try {
        await this.player.load(url, startTime);

        this.log("[ShakaPlayer] - video loaded");

        this.getTracks();
        this.getSubtitles();
      } catch (e) {
        this.log("[ShakaPlayer] - error ", e);
      }
    },
    loaded(event) {
      this.$emit("loaded", event);
    },
    unload() {
      this.log("[ShakaPlayer] - unload");
      if (this.player) {
        this.player.unload();
        this.player.destroy();
        this.player = null;
      }
    },
    detach() {
      this.player.detach();
    },
    destroy() {
      this.log("[ShakaPlayer] - destroy");
      if (this.player) {
        this.player.destroy();
        this.player = null;
      }
    },
    async setProtection() {
      const req = await fetch("/drm/fairplay.cer");
      const cert = await req.arrayBuffer();
      let licenseUrl = `/widevine/AcquireLicense`;
      let licenseHlsUrl = `/fairplay/AcquireLicense`;
      let licenseToken = await this.axios.get("/api/drmLicenseToken");

      try {
        this.player
          .getNetworkingEngine()
          .registerRequestFilter(function(type, request) {
            request.headers["X-AxDRM-Message"] = licenseToken.data.token;
          });
        await this.player.configure({
          manifest: {
            defaultPresentationDelay: 60,
            retryParameters: {
              timeout: 300000, // timeout in ms, after which we abort
              stallTimeout: 5000, // stall timeout in ms, after which we abort
              connectionTimeout: 10000, // connection timeout in ms, after which we abort
              maxAttempts: 100, // the maximum number of requests before we fail
              baseDelay: 1000, // the base delay in ms between retries
              backoffFactor: 2, // the multiplicative backoff factor between retries
              fuzzFactor: 0.5 // the fuzz factor to apply to each retry delay
            },
            dash: {
              ignoreMinBufferTime: true
            },
            hls: {
              useFullSegmentsForStartTime: true
            }
          },
          drm: {
            servers: {
              "com.widevine.alpha": licenseUrl,
              "com.microsoft.playready": licenseUrl,
              "com.apple.fps.1_0": licenseHlsUrl
            },
            advanced: {
              "com.apple.fps.1_0": { serverCertificate: new Uint8Array(cert) }
            },
            retryParameters: {
              timeout: 300000, // timeout in ms, after which we abort
              stallTimeout: 5000, // stall timeout in ms, after which we abort
              connectionTimeout: 10000, // connection timeout in ms, after which we abort
              maxAttempts: 100, // the maximum number of requests before we fail
              baseDelay: 1000, // the base delay in ms between retries
              backoffFactor: 2, // the multiplicative backoff factor between retries
              fuzzFactor: 0.5 // the fuzz factor to apply to each retry delay
            }
          },
          streaming: {
            bufferBehind: 60,
            bufferingGoal: 60,
            rebufferingGoal: 15,
            retryParameters: {
              timeout: 300000, // timeout in ms, after which we abort
              stallTimeout: 5000, // stall timeout in ms, after which we abort
              connectionTimeout: 10000, // connection timeout in ms, after which we abort
              maxAttempts: 100, // the maximum number of requests before we fail
              baseDelay: 1000, // the base delay in ms between retries
              backoffFactor: 2, // the multiplicative backoff factor between retries
              fuzzFactor: 0.5 // the fuzz factor to apply to each retry delay
            }
          },
          preferredAudioLanguage: this.$i18n.locale,
          preferredTextLanguage: this.$i18n.locale
        });
      } catch (e) {
        console.log(e, this.video);
        //this._videoPlayerErrorEvent()
      }
    },
    selectTrack(payload) {
      this.log("[ShakaPlayer] - selectTrack", payload);
      this.player.selectVariantTrack(
        { ...payload, label: payload.realLabel },
        true
      );
    },
    getTracks() {
      let interval = setInterval(() => {
        const tracks = this.player.getVariantTracks();

        const { currentTime } = this.video;
        if (currentTime > 0.3) {
          let newTracks = [];
          tracks.forEach(o => {
            const t = {
              id: o.id,
              name: o.bandwidth,
              active: o.active,
              label: o.label || o.language,
              realLabel: o.label,
              language: o.language
            };

            newTracks.push(t);
          });
          newTracks = newTracks.sort((a, b) => b.active - a.active);
          newTracks = this.unique(newTracks, "label");
          newTracks = newTracks.sort((a, b) => a.name - b.name);
          this.$emit("tracks", newTracks);
          clearInterval(interval);
        }
      }, 1000);
    },
    selectSubtitles(payload) {
      this.log("[ShakaPlayer] - selectSubtitles", payload);
      this.player.selectTextTrack(payload);
      this.player.setTextTrackVisibility(true);
    },
    disabledSubtitles(payload) {
      this.log("[ShakaPlayer] - disabledSubtitles", payload);
      this.player.setTextTrackVisibility(false);
    },
    getSubtitles() {
      let interval = setInterval(() => {
        const { currentTime } = this.video;
        if (currentTime > 0.3) {
          console.log(this.player.getTextTracks(), currentTime);
          this.$emit("subtitles", this.player.getTextTracks());
          clearInterval(interval);
        }
      }, 1000);
    },
    enableAdaptation(enabled) {
      this.log("[ShakaPlayer] - enableAdaptation", enabled);
      this.player.configure({
        abr: { enabled }
      });
    },
    onAdaptationEvent(event) {
      this.log("[ShakaPlayer:onAdaptationEvent]", event);
      this.getTracks();
      this.getSubtitles();
      // this.$emit('adaptation');
    },
    onTimeUpdate(event) {
      try {
        const { currentTime } = this.video;
        if (currentTime + 1 >= event.target.duration) {
          this.$emit("ended", true);
        }
        const stats = this.player.getStats();
        const data = {
          width: stats.width,
          height: stats.height,
          bitrate: stats.streamBandwidth
        };
        this.$emit("stats", data);
      } catch (e) {
        console.log(e);
      }
    },
    onBufferedData() {
      let behind = 0;
      let ahead = 0;
      const { currentTime, buffered } = this.video;
      for (let i = 0; i < buffered.length; i++) {
        // eslint-disable-line no-plusplus
        if (
          buffered.start(i) <= currentTime &&
          buffered.end(i) >= currentTime
        ) {
          ahead = buffered.end(i) - currentTime;
          behind = currentTime - buffered.start(i);
          break;
        }
      }
      const data = `- ${behind.toFixed(0)}s / + ${ahead.toFixed(0)}s`;
      this.$emit("buffer", data);
    },

    // Logger.
    log(...message) {
      console.log(message);
      this.$emit("log", ...message);
    },
    unique(array, property) {
      const compare =
        typeof property === "function"
          ? property
          : (left, right) => left[property] == right[property];

      const newArray = [];

      array.forEach(right => {
        const run = left => compare.call(this, left, right);
        var i = newArray.findIndex(run);
        if (i === -1) newArray.push(right);
      });

      return newArray;
    }
  }
};
</script>

<style scoped>
video {
  object-fit: fill;
}

video {
  background: #aaa;
  height: 100%;
  width: 100%;
}
</style>
