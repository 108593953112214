<template>
  <div id="controls" ref="videoControls" style="width:100%;opacity:1">
    <div class="video-controls">
      <input
        v-model="currentTime"
        :class="{ hidden: isAdvertise }"
        :disabled="isAdvertise"
        class="meter"
        data-var="--video-player-range"
        min="0"
        type="range"
        v-bind:max="duration"
        @input="onSeekChange"
      />
      <ul class="buttons">
        <li>
          <a
            id="play"
            :class="{ hidden: playing }"
            data-toggle="#pause"
            href=""
            @click.prevent="play()"
          >
            <img alt="" src="~@/assets/i/vp-play.svg" />
          </a>
          <a
            id="pause"
            :class="{ hidden: !playing }"
            data-toggle="#play"
            href=""
            @click.prevent="pause()"
          >
            <img alt="" src="~@/assets/i/vp-pause.svg" />
          </a>
        </li>
        <li class="time">
          {{ timeString }} <span>/ {{ durationString }}</span>
        </li>

        <li :class="{ off: muted }" class="volume  on-hover">
          <a data-show="#settings" @click="setMute"
            ><img alt="" src="~@/assets/i/vp-volume.svg"
          /></a>
          <div class="volume-bar">
            <div ref="volume" class="volume-active-range"></div>
            <input
              :value="volume"
              class="volume-range"
              data-var="--video-player-volume-range"
              max="1"
              min="0"
              step="any"
              type="range"
              @input="onVolumeChange"
            />
          </div>
        </li>
        <li>
          <a
            v-if="subtitles.length || audioTracks.length"
            href="#"
            @click.prevent="settings = !settings"
          >
            <img alt="" src="~@/assets/i/vp-cc.svg" />
          </a>
        </li>
        <li>
          <a
            v-if="Object.keys(data).length"
            href=""
            @click.prevent="toggleFullScreen"
          >
            <img alt="" src="~@/assets/i/vp-fullscreen.svg" />
          </a>
        </li>
      </ul>
    </div>
    <div
      v-show="settings && !isAdvertise && showing"
      id="settings"
      class="settings"
      style="display: block"
    >
      <a class="close" href="#" @click.prevent="settings = false"
        ><img alt="" src="~@/assets/i/modal-close.svg"
      /></a>

      <ul>
        <li v-if="tracks.length">
          <span>{{ $t("player.audio_tracks") }}</span>
          <a
            v-for="(track, index) in audioTracks"
            :key="index"
            :class="{
              selected: track.active
            }"
            href=""
            @click.prevent="$emit('changeTrack', track)"
            >{{ track.label }}</a
          >
        </li>

        <li v-if="subtitles.length">
          <span>{{ $t("player.subtitles") }}</span>
          <a
            :class="{
              selected: disabledSubtitles
            }"
            href="#"
            @click.prevent="$emit('disabledSubtitles', true)"
            >{{ $t("player.disabled_subtitles") }}</a
          >
          <a
            v-for="(subtitle, index) in subtitles"
            :key="index"
            :class="{
              selected: subtitle.active
            }"
            href="#"
            @click.prevent="$emit('changeSubtitles', subtitle)"
          >
            {{ subtitle.label }}
          </a>
        </li>

        <li class="volume">
          <span>{{ $t("player.volume") }}</span>
          <div class="volume-bar">
            <div ref="volumeSettings" class="volume-active-range"></div>
            <input
              :value="volume"
              class="volume-range"
              data-var="--video-player-volume-range"
              max="1"
              min="0"
              step="0.01"
              type="range"
              @input="onVolumeChange"
            />
          </div>
        </li>
      </ul>

      <div class="clear"></div>
    </div>
  </div>
</template>

<script>
import utils from "@/lib/utils";

export default {
  name: "Controls",
  components: {},
  props: [
    "video",
    "videoBlock",
    "tracks",
    "data",
    "subtitles",
    "isAdvertise",
    "disabledSubtitles",
    "fullscreen"
  ],
  data() {
    return {
      settings: false,
      loaded: false,
      playing: false,
      seeking: false,
      duration: 0,
      currentTime: 0,
      volume: 0.7,
      savedVolume: 0.7,
      muted: false,
      hiddenControlTimeout: false,
      showing: true
    };
  },
  computed: {
    audioTracks() {
      return this.tracks.map(item => {
        let curTrack = this.data.contentItems[0].audioTracks.find(
          it => item.label === it.lang.toLowerCase()
        );
        if (curTrack) {
          return { ...item, label: curTrack.name };
        }
        return item;
      });
    },
    timeString() {
      return utils.buildTimeString(this.currentTime, true);
    },
    durationString() {
      return utils.buildTimeString(this.duration, true);
    }
  },
  watch: {
    fullscreen() {
      this.toggleFullScreen();
    },
    video() {
      // Initialize when video is ready.
      this.init();
    },
    muted(value) {
      console.log(value, "watch");
    },
    currentTime(value) {
      let val = value / (this.duration / 100);
      if (val + 1 < 100) {
        val++;
      }
      document.documentElement.style.setProperty(
        "--video-player-range",
        val + "%"
      );
    }
  },
  created() {
    try {
      this.duration = this.data.contentItems[
        this.$route.params.episodes || 0
      ].durationSec;
    } catch (e) {
      this.duration = 0;
    }
  },
  mounted() {
    document.documentElement.style.setProperty("--video-player-range", "0%");
    document.documentElement.style.setProperty(
      "--video-player-volume-range",
      this.volume * 100 + "%"
    );
  },
  methods: {
    init() {
      this.setPlayerEvents();
    },

    setPlayerEvents() {
      // https://developer.mozilla.org/en-US/docs/Web/Guide/Events/Media_events
      this.video.addEventListener("playing", this.onPlaying);
      this.video.addEventListener("pause", this.onPause);
      this.video.addEventListener("durationchange", this.onDurationChange);
      this.video.addEventListener("timeupdate", this.onTimeUpdate);
      this.video.addEventListener("volumechange", this.onVolumeChange);
      this.$refs.videoControls.addEventListener(
        "mouseenter",
        this.showControls
      );
      this.$refs.videoControls.addEventListener(
        "mouseleave",
        this.hideControls
      );
      this.$refs.videoControls.addEventListener(
        "mouseenter",
        this.showControls
      );
      this.$refs.videoControls.addEventListener(
        "mouseleave",
        this.hideControls
      );
    },

    play() {
      this.$emit("play");
    },

    pause() {
      this.$emit("pause");
    },
    stop() {
      this.video.stop();
    },

    seekTo(value) {
      this.video.currentTime = value;
    },

    setMute() {
      this.muted = !this.muted;
      if (this.muted) {
        this.savedVolume = this.volume;
        this.volume = 0;
      } else {
        this.volume = this.savedVolume;
      }
      document.documentElement.style.setProperty(
        "--video-player-volume-range",
        this.volume * 100 + "%"
      );

      this.video.muted = this.muted;
    },

    onPlaying() {
      this.$emit("playing", true);
      this.video.style.removeProperty("height");
      if (this.fullscreen) {
        this.hideControls();
      }
      this.playing = true;
    },

    onPause() {
      this.$emit("playing", false);

      this.showControls();
      this.playing = false;
    },

    onSeekChange(event) {
      this.seeking = true;
      if (event.target && event.target.value) {
        this.seekTo(event.target.value);
      }
    },
    toggleFullScreen() {
      console.log(
        this.$browserDetect.isSafari,
        this.$browserDetect.isIOS,
        this.video.webkitEnterFullScreen
      );
      if (!this.fullscreen) {
        this.video.webkitExitFullscreen();
      }
      if (document.fullscreenElement) {
        document.exitFullscreen();
        this.$emit("update:fullscreen", false);
      } else if (
        (this.$browserDetect.isSafari || this.$browserDetect.isIOS) &&
        document.webkitFullscreenElement
      ) {
        // Need this to support Safari
        document.webkitExitFullscreen();
        this.$emit("update:fullscreen", false);
      } else if (
        (this.$browserDetect.isSafari || this.$browserDetect.isIOS) &&
        this.video.webkitEnterFullScreen
      ) {
        try {
          // Need this to support Safari
          this.video.webkitEnterFullScreen();
        } catch (e) {
          console.log(e);
        }

        this.$emit("update:fullscreen", true);
      } else {
        this.videoBlock.requestFullscreen();
        this.$emit("update:fullscreen", true);
      }
    },
    onVolumeChange(event) {
      if (event.target && event.target.value) {
        this.video.volume = parseFloat(event.target.value);
        this.volume = event.target.value;
      }

      this.$refs.volume.style.setProperty("width", this.volume * 100 + "%");
      this.$refs.volumeSettings.style.setProperty(
        "width",
        this.volume * 100 + "%"
      );

      this.muted = this.volume === 0 || this.volume === "0";
      this.video.muted = this.muted;
      document.documentElement.style.setProperty(
        "--video-player-volume-range",
        this.volume * 100 + "%"
      );
    },

    onDurationChange(event) {
      this.duration = event.target.duration;
    },

    onTimeUpdate(event) {
      if (this.isAdvertise) {
        if (this.currentTime + 0.3 < event.target.currentTime) {
          event.target.currentTime = this.currentTime;
        }
      }
      this.currentTime = event.target.currentTime;
      this.$emit("timeUpdate", this.currentTime);
    },
    hideControls() {
      if (this.video.paused) {
        return;
      }
      this.showing = false;
      this.settings = false;
      if (this.$refs.videoControls && this.$refs.videoControls.style) {
        this.$refs.videoControls.style.setProperty("opacity", 0);
      }
    },
    showControls() {
      this.showing = true;
      if (this.$refs.videoControls && this.$refs.videoControls.style) {
        this.$refs.videoControls.style.setProperty("opacity", 1);
      }
    }
  }
};
</script>

<style>
#controls {
  z-index: 2147483647;
}

video::-webkit-media-controls {
  display: none !important;
}

*::-webkit-media-controls-panel {
  display: none !important;
  -webkit-appearance: none;
}

/* Old shadow dom for play button */

*::-webkit-media-controls-play-button {
  display: none !important;
  -webkit-appearance: none;
}

/* New shadow dom for play button */

/* This one works! */

*::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none;
}

.activity-indicator {
  display: none !important;
}

*::-webkit-media-controls-panel {
  display: none !important;

  -webkit-appearance: none;
}

*::--webkit-media-controls-play-button {
  display: none !important;

  -webkit-appearance: none;
}

*::-webkit-media-controls-start-playback-button {
  display: none !important;

  -webkit-appearance: none;
}
</style>
